
import { QUIZ_PRODUCT_TYPES } from '@/services/constants';

export default {
  name: 'BaseButton',
  props: {
    type: {
      type: String,
      default: 'nutrition-generic',
      validator: (val) => [...Object.values(QUIZ_PRODUCT_TYPES), 'flat'].includes(val),
    },
    withIcon: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    computedStyles() {
      return ['base-button', `base-button--${this.type}`];
    },
    showRightIcon() {
      return this.withIcon && !this.disabled;
    },
  },
};
