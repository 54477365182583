
import { mapGetters } from 'vuex';
import { QUIZ_VARIANTS } from '@/services/constants';
export default {
  data() {
    return {
      time: 600,
    };
  },
  computed: {
    ...mapGetters('quiz', ['getQuizVariant']),
    formattedTime() {
      const minutes = Math.floor(this.time / 60);
      const seconds = this.time % 60;
      return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    },

    bannerTitle() {
      const variantTitleMap = {
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_LANDING_PAGE_BANNER_V1]: 'landingPage.flashBannerSale.titleSummer',
        [QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_LANDING_PAGE_BANNER_V2]: 'landingPage.flashBannerSale.titleFlash',
      };

      return this.$t(variantTitleMap[this.getQuizVariant] || 'landingPage.flashBannerSale.titleSummer');
    },
  },
  mounted() {
    setInterval(() => {
      if (this.time > 0) {
        this.time--;
      }
    }, 1000);
  },
};
