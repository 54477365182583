
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { Helpers } from '@/services/helpers';
import FlashBannerSale from '@/components/static/flash-banner-sale/flash-banner-sale';

export default {
  components: {
    FlashBannerSale,
  },
  props: {
    isDark: {
      type: Boolean,
      default: false,
    },
    sliders: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    ...mapGetters('quiz', ['getHeaderStatus']),
    availableLocales() {
      return this.$i18n.locales.filter((i) => i.code !== this.$i18n.locale);
    },
    devMode() {
      return Vue.config.devtools;
    },
    loggedin() {
      return Helpers.loggedin();
    },
    isBodyNutritionButton() {
      return (
        this.isBodyNutritionTour ||
        this.isWeightLossMonthlyTour ||
        this.isNutritionWeeklyStartTour ||
        this.isNutritionKetoTour ||
        this.shouldReplicateWeightLossMonthlyTour ||
        this.isMedsTour
      );
    },
    homeUrl() {
      const variantsMap = {
        [this.QUIZ_VARIANTS.DEFAULT]: 'home',
        [this.QUIZ_VARIANTS.PILATES_NEW_CHECKOUT]: 'homePilates',
        [this.QUIZ_VARIANTS.PILATES_LEGAL_DISCLAIMER]: 'homePilates',
        [this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_CHECKOUT]: 'homePilatesChallenge',
        [this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_LANDING_PAGE_BANNER_V1]: 'homePilatesChallenge',
        [this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_LANDING_PAGE_BANNER_V2]: 'homePilatesChallenge',
        [this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_SOMATIC_PILATES]: 'homePilatesChallengeSomatic',
        [this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_EMAIL_CTA_V1]: 'homePilatesChallenge',
        [this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_EMAIL_CTA_V2]: 'homePilatesChallenge',
        [this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_EMAIL_CTA_V3]: 'homePilatesChallenge',
        [this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_ADD_ANALYSIS_STEP_V1]: 'homePilatesChallenge',
        [this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_ADD_ANALYSIS_STEP_V2]: 'homePilatesChallenge',
        [this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_SECONDARY_TEXT]: 'homePilatesChallenge',
        [this.QUIZ_VARIANTS.PILATES_NOW]: 'homePilatesNow',
        [this.QUIZ_VARIANTS.BODY_NUTRITION]: 'homeBodyNutrition',
        [this.QUIZ_VARIANTS.HEALTH_NO_SUGAR_CHALLENGE]: 'homeNutrition28DayNoSugarChallenge',
        [this.QUIZ_VARIANTS.HEALTH_WEIGHT_LOSS_28_DAY_CHALLENGE]: 'homeNutrition28DayWeightLossChallenge',
        [this.QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY]: 'homeBodyNutrition',
        [this.QUIZ_VARIANTS.NUTRITION_WEELKLY_START]: 'homeNutritionWeeklyStart',
        [this.QUIZ_VARIANTS.BODY_NUTRITION_WEIGHT_LOSS_REASON_V1]: 'homeBodyNutrition',
        [this.QUIZ_VARIANTS.BODY_NUTRITION_WEIGHT_LOSS_REASON_V1]: 'homeBodyNutrition',
        [this.QUIZ_VARIANTS.BODY_NUTRITION_ADD_ANALYSIS_STEP_V1]: 'homeBodyNutrition',
        [this.QUIZ_VARIANTS.BODY_NUTRITION_ADD_ANALYSIS_STEP_V2]: 'homeBodyNutrition',
        [this.QUIZ_VARIANTS.NUTRITION_KETO]: 'homeNutritionKeto',
        [this.QUIZ_VARIANTS.NUTRITION_KETO_WEEKLY]: 'homeNutritionKetoWeekly',
        [this.QUIZ_VARIANTS.NUTRITION_KETO_AGE]: 'homeNutritionKetoWeekly',
        [this.QUIZ_VARIANTS.NUTRITION_KETO_WEEKLY_AGE]: 'homeNutritionKetoWeekly',
        [this.QUIZ_VARIANTS.FAST_WEIGHT_LOSS]: 'homeBodyNutrition',
        [this.QUIZ_VARIANTS.MACRO_DIET]: 'homeBodyNutrition',
        [this.QUIZ_VARIANTS.OPTIMAL_WEIGHT]: 'homeBodyNutrition',
        [this.QUIZ_VARIANTS.WEIGHT_LOSS_OVER_40]: 'homeBodyNutrition',
        [this.QUIZ_VARIANTS.WEIGHT_LOSS_WITHOUT_FASTING]: 'homeBodyNutrition',
        [this.QUIZ_VARIANTS.HEALTH_FOOD_IMPACT]: 'homeBodyNutrition',
        [this.QUIZ_VARIANTS.METABOLIC_RENEWAL]: 'homeBodyNutrition',
        [this.QUIZ_VARIANTS.CHOLESTEROL_CONTROL]: 'homeBodyNutrition',
        [this.QUIZ_VARIANTS.WEIGHT_LOSS_SECRET]: 'homeBodyNutrition',
        [this.QUIZ_VARIANTS.MEDS]: 'homeMeds',
        [this.QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE]: 'homeYogaChallenge',
      };

      return variantsMap[this.getQuizVariant] || variantsMap[this.QUIZ_VARIANTS.DEFAULT];
    },
    getTourImage() {
      const variantsMap = {
        [this.QUIZ_VARIANTS.DEFAULT]: '',
        [this.QUIZ_VARIANTS.PILATES_NEW_CHECKOUT]: 'rh-pilates-logo.png',
        [this.QUIZ_VARIANTS.PILATES_LEGAL_DISCLAIMER]: 'rh-pilates-logo.png',
        [this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_ADD_ANALYSIS_STEP_V1]: 'rh-pilates-logo.png',
        [this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_ADD_ANALYSIS_STEP_V2]: 'rh-pilates-logo.png',
        [this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_CHECKOUT]: 'rh-pilates-logo.png',
        [this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_LANDING_PAGE_BANNER_V1]: 'rh-pilates-logo.png',
        [this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_LANDING_PAGE_BANNER_V2]: 'rh-pilates-logo.png',
        [this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_SOMATIC_PILATES]: 'rh-pilates-logo.png',
        [this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_EMAIL_CTA_V1]: 'rh-pilates-logo.png',
        [this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_EMAIL_CTA_V2]: 'rh-pilates-logo.png',
        [this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_EMAIL_CTA_V3]: 'rh-pilates-logo.png',
        [this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_SECONDARY_TEXT]: 'rh-pilates-logo.png',
        [this.QUIZ_VARIANTS.PILATES_NOW]: 'rh-pilates-logo.png',
        [this.QUIZ_VARIANTS.BODY_NUTRITION]: 'rh-health-logo-new.png',
        [this.QUIZ_VARIANTS.HEALTH_NO_SUGAR_CHALLENGE]: 'rh-health-logo-new.png',
        [this.QUIZ_VARIANTS.HEALTH_WEIGHT_LOSS_28_DAY_CHALLENGE]: 'rh-health-logo-new.png',
        [this.QUIZ_VARIANTS.NUTRITION_KETO]: 'rh-keto-logo.png',
        [this.QUIZ_VARIANTS.NUTRITION_KETO_WEEKLY]: 'rh-keto-logo.png',
        [this.QUIZ_VARIANTS.NUTRITION_KETO_AGE]: 'rh-keto-logo.png',
        [this.QUIZ_VARIANTS.NUTRITION_KETO_WEEKLY_AGE]: 'rh-keto-logo.png',
        [this.QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY]: 'rh-health-logo-new.png',
        [this.QUIZ_VARIANTS.NUTRITION_WEELKLY_START]: 'rh-health-logo-new.png',
        [this.QUIZ_VARIANTS.BODY_NUTRITION_WEIGHT_LOSS_REASON_V1]: 'rh-health-logo-new.png',
        [this.QUIZ_VARIANTS.BODY_NUTRITION_WEIGHT_LOSS_REASON_V2]: 'rh-health-logo-new.png',
        [this.QUIZ_VARIANTS.BODY_NUTRITION_ADD_ANALYSIS_STEP_V1]: 'rh-health-logo-new.png',
        [this.QUIZ_VARIANTS.BODY_NUTRITION_ADD_ANALYSIS_STEP_V2]: 'rh-health-logo-new.png',
        [this.QUIZ_VARIANTS.FAST_WEIGHT_LOSS]: 'rh-health-logo-new.png',
        [this.QUIZ_VARIANTS.MACRO_DIET]: 'rh-health-logo-new.png',
        [this.QUIZ_VARIANTS.OPTIMAL_WEIGHT]: 'rh-health-logo-new.png',
        [this.QUIZ_VARIANTS.WEIGHT_LOSS_OVER_40]: 'rh-health-logo-new.png',
        [this.QUIZ_VARIANTS.WEIGHT_LOSS_WITHOUT_FASTING]: 'rh-health-logo-new.png',
        [this.QUIZ_VARIANTS.HEALTH_FOOD_IMPACT]: 'rh-health-logo-new.png',
        [this.QUIZ_VARIANTS.METABOLIC_RENEWAL]: 'rh-health-logo-new.png',
        [this.QUIZ_VARIANTS.CHOLESTEROL_CONTROL]: 'rh-health-logo-new.png',
        [this.QUIZ_VARIANTS.WEIGHT_LOSS_SECRET]: 'rh-health-logo-new.png',
        [this.QUIZ_VARIANTS.MEDS]: 'rh-meds-logo.png',
        [this.QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE]: 'rh-yoga-logo.png',
      };

      return variantsMap[this.getQuizVariant] || variantsMap[this.QUIZ_VARIANTS.DEFAULT];
    },
    getHamBurgerIcon() {
      return [
        this.QUIZ_VARIANTS.BODY_NUTRITION,
        this.QUIZ_VARIANTS.HEALTH_NO_SUGAR_CHALLENGE,
        this.QUIZ_VARIANTS.HEALTH_WEIGHT_LOSS_28_DAY_CHALLENGE,
        this.QUIZ_VARIANTS.WEIGHT_LOSS_MONTHLY,
        this.QUIZ_VARIANTS.BODY_NUTRITION_WEIGHT_LOSS_REASON_V1,
        this.QUIZ_VARIANTS.BODY_NUTRITION_WEIGHT_LOSS_REASON_V2,
        this.QUIZ_VARIANTS.BODY_NUTRITION_ADD_ANALYSIS_STEP_V1,
        this.QUIZ_VARIANTS.BODY_NUTRITION_ADD_ANALYSIS_STEP_V2,
        this.QUIZ_VARIANTS.PILATES_NEW_CHECKOUT,
        this.QUIZ_VARIANTS.PILATES_LEGAL_DISCLAIMER,
        this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_CHECKOUT,
        this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_LANDING_PAGE_BANNER_V1,
        this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_LANDING_PAGE_BANNER_V2,
        this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_SOMATIC_PILATES,
        this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_EMAIL_CTA_V1,
        this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_EMAIL_CTA_V2,
        this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_EMAIL_CTA_V3,
        this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_ADD_ANALYSIS_STEP_V1,
        this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_ADD_ANALYSIS_STEP_V2,
        this.QUIZ_VARIANTS.PILATES_NOW,
        this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_NEW_SECONDARY_TEXT,
        this.QUIZ_VARIANTS.NUTRITION_KETO,
        this.QUIZ_VARIANTS.NUTRITION_KETO_WEEKLY,
        this.QUIZ_VARIANTS.NUTRITION_KETO_AGE,
        this.QUIZ_VARIANTS.NUTRITION_KETO_WEEKLY_AGE,
        this.QUIZ_VARIANTS.FAST_WEIGHT_LOSS,
        this.QUIZ_VARIANTS.MACRO_DIET,
        this.QUIZ_VARIANTS.OPTIMAL_WEIGHT,
        this.QUIZ_VARIANTS.WEIGHT_LOSS_OVER_40,
        this.QUIZ_VARIANTS.WEIGHT_LOSS_WITHOUT_FASTING,
        this.QUIZ_VARIANTS.HEALTH_FOOD_IMPACT,
        this.QUIZ_VARIANTS.METABOLIC_RENEWAL,
        this.QUIZ_VARIANTS.CHOLESTEROL_CONTROL,
        this.QUIZ_VARIANTS.WEIGHT_LOSS_SECRET,
        this.QUIZ_VARIANTS.MEDS,
        this.QUIZ_VARIANTS.YOGA_28_DAY_CHALLENGE,
      ].includes(this.getQuizVariant)
        ? 'icon-hamburger-menu'
        : 'icon-hamburger';
    },
    coloredHeader() {
      return [
        this.QUIZ_VARIANTS.PILATES_NOW,
        this.QUIZ_VARIANTS.NUTRITION_KETO,
        this.QUIZ_VARIANTS.NUTRITION_KETO_WEEKLY,
        this.QUIZ_VARIANTS.NUTRITION_KETO_AGE,
        this.QUIZ_VARIANTS.NUTRITION_KETO_WEEKLY_AGE,
        this.QUIZ_VARIANTS.MEDS,
      ].includes(this.getQuizVariant);
    },
    shouldRenderBanner() {
      const allowedVariants = [
        this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_LANDING_PAGE_BANNER_V1,
        this.QUIZ_VARIANTS.PILATES_28_DAY_CHALLENGE_LANDING_PAGE_BANNER_V2,
      ];

      return allowedVariants.includes(this.getQuizVariant);
    },
  },
  watch: {
    $route() {
      this.isOpen = false;
    },
  },
  methods: {
    navigationHandler() {
      this.isOpen = !this.isOpen;
    },
    logout() {
      Helpers.logout();
      window.location.href = this.$getUrl('home');
    },
    handleDefaultState() {
      this.$router.push('/quiz/start');
    },
  },
};
